/* ItemListTable.css */

.table-container {
    /* padding: 20px; */
    /* margin: 20px; */
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* Additional custom styles */
table {
    width: 100%;
}

th {
    font-weight: bold;
}

tbody tr:hover {
    background-color: #f2f2f2;
}

td {
    vertical-align: middle;
}

td.actions {
    display: flex;
    justify-content: center;
    align-items: center;
}

.textarea-container {
    border: 2px solid #ced4da;
    border-radius: 4px;
    padding: 8px;
}

.textarea-container textarea {
    border: none;
    resize: vertical;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}
